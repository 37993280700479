import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../Assets/landingPage.css";

// Loading component
const LoadingSpinner = () => (
  <div style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh' 
  }}>
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

// Debug wrapper for lazy loading
const debugLazyLoad = (importFn, componentName) => {
  console.log(`Attempting to lazy load: ${componentName}`);
  return lazy(() => {
    return importFn()
      .then(module => {
        if (!module || !module.default) {
          throw new Error(`Module ${componentName} does not have a default export`);
        }
        console.log(`Successfully loaded: ${componentName}`);
        return module;
      })
      .catch(error => {
        console.error(`Failed to load ${componentName}:`, error);
        throw error;
      });
  });
};

// Lazy load all components with debugging
const Track = debugLazyLoad(() => import("../Dashboard/Track"), "Track");
const Dashboard = debugLazyLoad(() => import("../Dashboard/Dashboard"), "Dashboard");
const TrashCan = debugLazyLoad(() => import("../Dashboard/TrashPost"), "TrashCan");
const ConsigneeList = debugLazyLoad(() => import("../Dashboard/consigneeList"), "ConsigneeList");
const ShippersList = debugLazyLoad(() => import("../Dashboard/shippersList"), "ShippersList");
const ViewDAT = debugLazyLoad(() => import("../Dashboard/ViewDAT"), "ViewDAT");
const ViewTruckstop = debugLazyLoad(() => import("../Dashboard/ViewTruckstop"), "ViewTruckstop");
const TruckerPath = debugLazyLoad(() => import("../Dashboard/TruckerPath"), "TruckerPath");
const CompanyList = debugLazyLoad(() => import("../Dashboard/companyDetails"), "CompanyList");
const TrackTruck = debugLazyLoad(() => import("../Dashboard/TrackTruck"), "TrackTruck");
const TermsAndConditions = debugLazyLoad(() => import("../Dashboard/termsAndCondition"), "TermsAndConditions");
const PrivacyPolicy = debugLazyLoad(() => import("../Dashboard/PrivacyPolicy"), "PrivacyPolicy");
const SupportPage = debugLazyLoad(() => import("../Dashboard/SupportPage"), "SupportPage");
const ContactPage = debugLazyLoad(() => import("../LandingPage/contactPage"), "ContactPage");
const About = debugLazyLoad(() => import("../LandingPage/about"), "About");
const Demo = debugLazyLoad(() => import("../LandingPage/demo"), "Demo");
const LandingPage = debugLazyLoad(() => import("../LandingPage/LandingPage"), "LandingPage");
const NotFound = debugLazyLoad(() => import("../LandingPage/NotFound"), "NotFound");
const CombinedTable = debugLazyLoad(() => import("../Dashboard/combinedTableData"), "CombinedTable");
const DirectFreight = debugLazyLoad(() => import("../Dashboard/DirectFreight"), "DirectFreight");
const SinglePageTracking = debugLazyLoad(() => import("../Dashboard/SinglePageTracking"), "SinglePageTracking");
const UserProfile = debugLazyLoad(() => import("../Dashboard/Settings"), "UserProfile");
const DriverPrivacyPolicy = debugLazyLoad(() => import("../Dashboard/DriverPrivacyPolicy"), "DriverPrivacyPolicy");

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}>
          <div>
            <h2>Something went wrong.</h2>
            <p>{this.state.error?.message}</p>
            <button onClick={() => window.location.reload()}>
              Reload Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const Main = () => {
  const uid = localStorage.getItem("id");
  
  const PrivateRoute = ({ children }) => {
    return uid ? children : <Navigate to="/" />;
  };
  
  const PublicRoute = ({ children }) => {
    return !uid ? children : <Navigate to="/dashboard" />;
  };
  
  const NotFoundChild = () => {
    return (
      <div>
        <Container
          style={{
            color: "#000",
            margin: "8% auto",
            height: "54vh",
            paddingTop: "10%",
            background: "transparent",
          }}
        >
          <h1 style={{ color: "#000" }}>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for could not be found.</p>
        </Container>
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route exact path="*" element={<NotFound />} />
          <Route
            exact
            path="/"
            element={
              <PublicRoute>
                <LandingPage />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/terms_and_conditions"
            element={
              <PublicRoute>
                <TermsAndConditions />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/support"
            element={
              <PublicRoute>
                <SupportPage />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/about"
            element={
              <PublicRoute>
                <About />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/demo"
            element={
              <PublicRoute>
                <Demo />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/contact_us"
            element={
              <PublicRoute>
                <ContactPage />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/privacy_policy"
            element={
              <PublicRoute>
                <PrivacyPolicy />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/driver_privacy_policy"
            element={
              <PublicRoute>
                <DriverPrivacyPolicy />
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/live-tracking/:id"
            element={<SinglePageTracking />}
          />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route index element={<Track />} />
            <Route exact path="Trash" element={<TrashCan />} />
            <Route exact path="consignee-details" element={<ConsigneeList />} />
            <Route exact path="shippers-details" element={<ShippersList />} />
            <Route exact path="view-dat" element={<ViewDAT />} />
            <Route exact path="direct-freight" element={<DirectFreight />} />
            <Route exact path="view-truckstop" element={<ViewTruckstop />} />
            <Route exact path="Trucker-Path" element={<TruckerPath />} />
            <Route exact path="Company-List" element={<CompanyList />} />
            <Route exact path="Combined-List" element={<CombinedTable />} />
            <Route exact path="trucks" element={<TrackTruck />} />
            <Route exact path="settings" element={<UserProfile />} />
            <Route exact path="*" element={<NotFoundChild />} />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Main;
